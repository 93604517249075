import { Injectable } from '@angular/core';

/**
 * Access to the global window variable.
 */
declare const window: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  unsetCustomDimension(dimensionIndex: number): void {
    window?._paq?.push(['deleteCustomDimension', dimensionIndex]);
  }

  unsetCustomDimensions(dimensionIndices: number[]): void {
    dimensionIndices.forEach(this.unsetCustomDimension);
  }

  disableHeatmapTracking(): void {
    window?._paq?.push(['HeatmapSessionRecording.disable']);
  }
}
